.mainBackground {
  height: 100%;
  background: linear-gradient(
    112.75deg,
    rgba(157, 71, 169, 0.75) 0.42%,
    rgba(239, 64, 140, 0.75) 99.42%
  );
}

.subContainer {
  background-image: url("../../Assets/Onboarding/ed5newBg.png");
  background-size: 100% 100%;
  height: 100%;
  padding-bottom: 40%;
}

.heading {
  display: flex;
  align-items: center;
  max-width: fit-content;
  padding: 30px;
  color: white;
  font-family: "Barlow-semibold", sans-serif;
  font-size: 24px;
}

.chapterTotal{
  display: flex;
  justify-content: space-around;
  align-items: center;
  padding: 10px;
  border: 1px solid white;
  margin: 10px;
  box-sizing: border-box;
  border-radius: 12px;
  background-color: #ffffff47;
  color: white;
  font-size: 18px;
  font-weight: 700;
}

.centerContainer {
  max-width: 1400px;
  margin: 0 auto;
  text-align: center;
  margin-top: 8%;
}

.selectionHeading {
  color: white;
  margin-bottom: 60px;
}

.boards {
  display: flex;
  justify-content: space-around;
}

.containerBoard {
  width: 200px;
  border: 1px solid white;
  padding: 20px;
  border-radius: 12px;
  color: white;
  cursor: pointer;
}

.boardselect {
  background-color: white;
  color: pink;
}

.btns {
  position: fixed;
  bottom: 41px;
  right: 75px;
  padding: 14px;
  width: 150px;
  border: none;
  border-radius: 12px;
  background-color: #fbd7e9;
  color: #f69bc7;
  font-size: 20px;
  font-weight: 600;
  cursor: pointer;
}

.selectedTab {
  width: 215px;
  border: 2px solid #ffffff47;
  background-color: #ffffffcc;
  padding: 10px;
  margin-bottom: 5px;
  border-radius: 7px;
  cursor: pointer;
  color: black;
  font-size: 22px;
  font-weight: 400;
  /* font-family: Barlow; */
}

.unselectedTab {
  padding: 5px 10px;
  width: 215px;
  cursor: pointer;
  color: white;
  margin-bottom: 12px;
  font-size: 22px;
  font-weight: 400;
  /* font-family: Barlow; */
}

.chapterMain {
  max-width: 1400px;
  margin: 0 auto;
}

td, th {
    text-align: center;
}

th{
    font-size: 23px;
    font-weight: 600;
}

td{
    font-size: 20px;
    font-weight: 500;
}

table{
    border-collapse: separate !important;
    border-spacing: 0;
    border-radius : 10px;
}

.chapterT{
  display: flex;
  width: 220px;
  align-items: center;
  padding: 6px;
  background-color: #ffffff47;
  color: white;
  font-size: 18px;
  justify-content: center;
  border-radius: 12px;
}