.contentCode {
  overflow-x: scroll;
  overscroll-behavior-inline: contain;
  scrollbar-width: thin;
  scrollbar-color: rgb(99, 98, 98) transparent;
  padding: 10px;
}

.contentCode::-webkit-scrollbar {
  width: 8px !important;
  height: 8px;
  margin-top: 5px !important;
}

.editIconsty {
  font-size: 30px;
  padding: 5px;
  border-radius: 50%;
  color: #fff;
  position: absolute;
  top: 10px;
  left: 10px;
  background-color: rgb(0, 0, 0 , 0.5);
}
