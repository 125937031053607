.tabMainContainer {
  display: flex;
  flex-direction: row;
  gap: 10px;
  align-items: center;
  margin-bottom: 15px;
}
.tabContainer {
  width: 120px;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 10px;
  cursor: pointer;
  border-radius: 10px;
  font-size: 20px;
}

.activeButton {
  background-color: #ED388F;
  color: white !important;
  font-weight: bold;
}
.btnContainer {
  display: flex;
  justify-content: space-between;
  margin-bottom: 10px;
}

.profileHead {
  font-size: 1.5rem;
  font-weight: 600;
  line-height: 1.25;
  border-bottom: 0.5px solid #cdc8c8;
  padding: 5px;
  margin: 0 0 20px;
  color: #4c4848;
}

.displayEditfield {
  margin: 20px;
  width: 350px;
  margin: 20px auto;
  border: 1px solid gray;
  border-radius: 10px;
}

.btnContainer {
  display: flex;
  justify-content: space-between;
  margin-bottom: 10px;
}

.createBtn {
  border: none;
  color: white;
  background-color: #ED388F;
  padding: 10px 22px;
  border-radius: 5px;
}
