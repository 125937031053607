.headerTitle {
    display: flex;
}

.head{
    font-size: 25px;
  }

.title {
    margin: 5px;
    font-weight: 500;
    font-size: 20px;
    color: #484848;
}

.subtitle {
    font-weight: 500;
    font-size: 18px;
    margin: 0px;
    color: #484848;
}

.assignment {

    display: flex;
    align-items: center;
    margin-top: 10px;

}

.iconStyles {
    color: #ED388F;
}

.assign_data{
    display: flex;
    justify-content: end;
}

.assignmentList{
    box-shadow: 0px 0px 3px rgb(0 0 0 / 29%);
    border-radius: 5px;
    padding: 15px;
    margin-bottom: 15px;
    background: white;
}

.fontTitle{
    font-size: 24px;
}

.questionData{
    display: flex;
    font-size: 17px;
}

.questionData > div{
    margin: 10px;
    color: grey;
}

.questionData > div:first-child{
    margin-right: 0;
    margin-left: 0;
    padding-right: 10px;
    border-right: 2px solid grey;
}

.subAssignment{
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.arrowBack{
    width: max-content;
    display: flex;
    margin: 20px 0;
    color: #ED388F;
    cursor: pointer;
  }