


.popUpCon{
    width : 50%;
    padding : 20px;
    border-radius : 20px;
 
 }
 
 .editHead{
     color : black;
     font-size : 22px;
     font-weight : 400;
 }
 .editPara{
     color : #FFFFFF;
     font-size : 22px;
     font-weight : 400;
 
 }
 .imagesCon{
     margin-bottom: 50px;
     margin-top : 50px;
 
 }
 .slideType{
     color : #FFFFFF;
     font-size : 20px;
     font-weight : 400;
 }
 .deleteBtn{
     color : #FFFFFF;
     font-size : 22px;
     font-weight : 400;
     border : 1px solid #FFFFFF;
     border-radius: 6px;
     padding : 10px;
     cursor: pointer;
 }
 .cancelBtn{
     color : #FFFFFF;
     font-size : 22px;
     font-weight : 400;
     border : none;
     border-radius: 6px;
     padding : 10px;
     background-color: transparent;
     cursor: pointer;

 }