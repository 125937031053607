.mainBackground {
  height: 100%;
  background: linear-gradient(
    112.75deg,
    rgba(157, 71, 169, 0.75) 0.42%,
    rgba(239, 64, 140, 0.75) 99.42%
  );
}

.subContainer {
  background-image: url("../../Assets/Onboarding/ed5newBg.png");
  background-size: 100% 100%;
  height: 100%;
  padding: 0 0 10%;
}

.heading {
  display: flex;
  align-items: center;
  max-width: fit-content;
  padding: 30px;
  color: white;
  font-size: 24px;
  color: var(--color-main-white, #fff);
  text-shadow: 0px 4px 10px rgba(0, 0, 0, 0.25);
  /* Font/Desktop/H3/Medium */
  font-family: 'Barlow-semibold', sans-serif;
  font-size: 32px;
  font-style: normal;
  font-weight: 500;
  line-height: 40px; /* 125% */
  letter-spacing: -0.64px;
}

.centerContainer {
  max-width: 1400px;
  margin: 0 auto;
  text-align: center;
  margin-top: 2%;
  display: flex;
  justify-content: space-around;
}

.selectionHeading {
  color: white;
  margin-bottom: 60px;
}

.paras{

  font-family: 'Barlow-semibold', sans-serif;
  font-size: 18px;
}

.boards {
  display: flex;
  justify-content: space-around;
}

.containerBoard {
  width: 200px;
  border: 1px solid white;
  padding: 20px;
  border-radius: 12px;
  color: white;
  cursor: pointer;
}

.boardselect {
  background-color: white;
  color: pink;
}

.btns {
  position: fixed;
  bottom: 41px;
  right: 75px;
  padding: 14px;
  width: 150px;
  border: none;
  border-radius: 12px;
  background-color: #fbd7e9;
  color: #f69bc7;
  font-size: 20px;
  font-weight: 600;
  cursor: pointer;
  font-family: 'Barlow', sans-serif;
}

.startDate {
  display: flex;
  justify-content: center;
  align-items: center;
  margin-bottom: 20px;
}

.periodData {
  color: white;
  text-align: left;
  margin-bottom: 20px;
  display: flex;
  align-items: center;
  /* justify-content: space-between; */
}

.dragData {
  display: flex;
  justify-content: space-between;
  width: 360px;
  border: 1px solid #ffffff59;
  padding: 10px;
  margin-top: 20px;
  margin-bottom: 20px;
  border-radius: 4px;
  font-size: 21px;
  align-items: center;
  color: white;
  background-color: #d9c8c863;
}

.dataContain {
  width: 150px;
  font-size: 17px;

  font-family: 'Barlow-semibold', sans-serif;
}

.drags{
    font-size: 19px;
    color: white;
}

.breaks{
  background-color: #f6f6f6cf;
  color: #000000db;
}