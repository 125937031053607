.pendingFees {
  /* border: 1px solid black; */
  width: 100%;
  padding: 10px;
}

.divider {
  border-bottom: 1px solid #c3c3c3;
}


/* globals */

.font22 {
  font-size: 16px;
}


.imagesContainer {
  width: 65px;
}

.images {
  width: 100%;
  border-radius: 100%;
  height: 65px;
  object-fit: cover;
}