.mainBackground {
  height: 100%;
  background: linear-gradient(
    112.75deg,
    rgba(157, 71, 169, 0.75) 0.42%,
    rgba(239, 64, 140, 0.75) 99.42%
  );
}

.subContainer {
  background-image: url("../../Assets/Onboarding/ed5newBg.png");
  background-size: 100% 100%;
  height: 100%;
  padding-bottom: 34%;
}

.heading {
  display: flex;
  align-items: center;
  max-width: fit-content;
  padding: 30px 30px 2px;
  color: white;
  font-size: 24px;
  font-family: "Barlow-semibold", sans-serif;
}

.centerContainer {
  max-width: 1400px;
  margin: 0 auto;
  text-align: center;
  margin-top: 8%;
}

.selectionHeading {
  color: white;
  margin-bottom: 60px;
}

.boards {
  display: flex;
  justify-content: space-around;
}

.containerBoard {
  width: 200px;
  border: 1px solid white;
  padding: 20px;
  border-radius: 12px;
  color: white;
  cursor: pointer;
}

.boardselect {
  background-color: white;
  color: pink;
}

.btns {
  position: fixed;
  bottom: 41px;
  right: 75px;
  padding: 14px;
  width: 150px;
  border: none;
  border-radius: 12px;
  background-color: #fbd7e9;
  color: #f69bc7;
  font-size: 20px;
  font-weight: 600;
  cursor: pointer;

  font-family: "Barlow", sans-serif;
}

.dragstitle {
  margin: 20px 60px 0;
  font-size: 20px;
  color: white;

  color: var(--color-main-white, #fff);
  /* Font/Desktop/H1/Medium */
  font-family: "Barlow-semibold", sans-serif;
  font-size: 28px;
  font-style: normal;
  font-weight: 400;
}

.dropzone {
  /* width: 600px;
    border: 1px solid white;
    text-align: center;
    margin: 27px;
    border-radius: 21px;
    min-height: 450px; */

  width: 772px;
  border: 1px solid white;
  text-align: center;
  margin: 27px;
  border-radius: 21px;
  min-height: 305px;
  border-radius: 12px;
  border: 1px solid var(--gradient-tabs-stroke, rgba(255, 255, 255, 0.5));
  background: var(
    --gradient-tabs-background,
    linear-gradient(
      238deg,
      rgba(255, 255, 255, 0.2) -2.49%,
      rgba(255, 255, 255, 0.04) 85.03%
    )
  );
  display: flex;
  padding: 16px;
  flex-direction: column;
  align-items: center;
  gap: 24px;
  flex-shrink: 0;
  align-self: stretch;
}

.classData {
  width: 170px;
  padding: 8px;
  margin-bottom: 8px;
  background: rgba(255, 255, 255, 0.28);
  border: 2px solid #ffffff;
  border-radius: 3px;
  text-align: center;
  color: white;
  margin: 5px auto;
  display: flex;
  height: 54px;
  padding: 3px 16px;
  flex-direction: row;
  font-size: 20px;
  font-family: "Barlow-semibold", sans-serif;
  justify-content: space-between;
  align-items: center;
  margin: 10px;
  flex-shrink: 0;
  align-self: stretch;
  border-radius: 6px;
  border: 1px solid var(--gradient-tabs-stroke, rgba(255, 255, 255, 0.5));
  background: var(
    --gradient-tabs-background,
    linear-gradient(
      238deg,
      rgba(255, 255, 255, 0.2) -2.49%,
      rgba(255, 255, 255, 0.04) 85.03%
    )
  );
}

.containers {
  /* height: 700px; */
  /* overflow: auto; */
  margin: 30px;
}

.mainClass {
  border-radius: 6px;
  border: 1px solid var(--gradient-tabs-stroke, rgba(255, 255, 255, 0.5));
  background: var(
    --gradient-tabs-background-active,
    linear-gradient(
      151deg,
      rgba(255, 255, 255, 0.9) 7.05%,
      rgb(255 255 255) 90.08%
    )
  );
  color: black;
  justify-content: space-around;
  padding: 0 32px;
}
