.containProfile {
    /* max-width: 1300px; */
    margin: 0 auto;
}

.container {
    width: 100%;
}

.largeFont {
    font-size: 40px;
}

.titleFont {
    font-size: 30px;
}

.subTitleFont {
    font-size: 25px;
}

.fontNormal {
    font-size: 18px;
}

.fontxs {
    font-size: 15px;
}

.headerWrapper {
    margin: 25px 0;
    gap: 20px;
}

.disFlex {
    display: flex;
    margin-bottom: 7px;
}

.marginData {
    margin: 2px 10px;
}

.left {
    box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.2);
    border-radius: 10px;
}

.profileCard {
    position: relative;
}

.profileCard>.familyimg {
    width: 100%;
    border-top-left-radius: 10px;
    border-top-right-radius: 10px;
    position: relative;
    height: 600px;
}

.EditContainer {

  padding: 10px;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 25px;
  box-shadow: rgb(0 0 0 / 24%) 0px 3px 8px;
  position: absolute;
  right: 2%;
  bottom: 5%;
  background: rgb(255 247 247 / 75%);
  }
  
  .profileimgContainer {
    width: 120px;
    position: absolute;
    margin-top: -64px;
    margin-left: 52px;
  }

  .btn_style{
    padding: 10px 30px;
    width: 180px;
    font-size: 18px;
    border: none;
    border-radius: 10px;
    margin: 14px;
    background-color: #ED388F;
    color: white;
  }

  .profileInnerContainer{
    position: relative;
  }

  .profileImg {
    border-radius: 50%;
    width: 110px;
    height: 110px;
  }
  .camera{
    position: absolute;
      bottom: 0;
      right: 0;
      background: white;
      padding: 10px;
      border-radius: 50%;
  }

  .profileUser{
    display: flex;
    align-items: baseline;
    justify-content: center;
    padding: 20px;
    background: #A497C31F;
  }

  .profileblock{
    margin: 0 30px;
  }
  
  .personalBox{
    width: 30%;
  }

  .userName {
    text-align: center;
    margin: 0;
    font-size: 28px;
    font-weight: 400;
    padding: 10px;
  }

  .userLocation {
    margin: 0;
    padding: 0;
    text-align: center;
  }

  .userId{
    font-size: 20px;
    text-align: center;
    margin: 0;
    font-weight: 400;
    padding: 10px;
  }

  .userGrey{
    margin: 0;
    color: grey;
  }
  .editButton {
    border: none;
    background-color: rgb(255 247 247 / 5%);
    font-size: 14px;
    font-weight: 520;
  }  

  .PersonalDetails{
    box-shadow: 2px 2px 7px rgb(199 199 199);
    padding: 20px;
    box-sizing: border-box;
    border-radius: 13px;
    margin-bottom: 15px;
  }

  .personalData{
    display: flex;
    justify-content: space-between;
    align-items: center;
  }
  
  .personal{
    display: flex;
    justify-content: space-between;
    margin-bottom: 20px;
  }