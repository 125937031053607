.mainBackground {
  height: 100%;
  background: linear-gradient(
    112.75deg,
    rgba(157, 71, 169, 0.75) 0.42%,
    rgba(239, 64, 140, 0.75) 99.42%
  );
}

.subContainer {
  background-image: url("../../Assets/Onboarding/ed5newBg.png");
  background-size: 100% 100%;
  height: 100%;
  padding: 0 0 20%;
}

.headerContainer {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0 30px;
}

.uploadContainer {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  text-align: center;
}
.teacherContainer {
  width: 60%;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
}

.sampleDownload {
  padding: 11px 35px;
  background: white;
  border-radius: 12px;
  color: #a57981;
  text-decoration: none;
}

.heads {
  color: var(--color-main-white, #fff);
  text-align: center;
  /* Font/Desktop/H1/Medium */
  font-family: "Barlow-semibold", sans-serif;
  font-size: 35px;
  margin: 0 auto;
}

.paras {
  color: var(--color-main-white, #fff);
  /* Font/Paragraph/XX Large/Regular */
  font-family: "Barlow-semibold", sans-serif;
  font-size: 24px;
}
.heading {
  display: flex;
  align-items: center;
  max-width: fit-content;
  padding: 30px;
  color: white;
  font-size: 24px;

  color: var(--color-main-white, #fff);
  text-shadow: 0px 4px 10px rgba(0, 0, 0, 0.25);
  /* Font/Desktop/H3/Medium */
  font-family: "Barlow-semibold", sans-serif;
  font-size: 32px;
  font-style: normal;
  font-weight: 500;
  line-height: 40px; /* 125% */
  letter-spacing: -0.64px;
}

.centerContainer {
  max-width: 1400px;
  margin: 0 auto;
  text-align: center;
  margin-top: 8%;
}

.selectionHeading {
  color: white;
  margin-bottom: 60px;
}

.boards {
  display: flex;
  justify-content: space-around;
}

.containerBoard {
  width: 200px;
  border: 1px solid white;
  padding: 20px;
  border-radius: 12px;
  color: white;
  cursor: pointer;
}

.boardselect {
  background-color: white;
  color: pink;
}

.btns {
  position: fixed;
  bottom: 41px;
  right: 75px;
  padding: 14px;
  width: 150px;
  border: none;
  border-radius: 12px;
  background-color: #fbd7e9;
  color: #f69bc7;
  font-size: 20px;
  font-weight: 600;
  cursor: pointer;
  font-family: "Barlow", sans-serif;;
}

.dragDrop {
  border: 2px dashed white;
  /* width: 636px; */
  width: 100%;
  margin: 59px auto;
  text-align: center;
  padding: 79px;
  box-sizing: border-box;
  border-radius: 10px;
}

.tableData {
  max-width: max-content;
  margin: 0 auto;
  overflow: auto;
}

/* Hide scrollbar for Chrome, Safari and Opera */
/* .tableData::-webkit-scrollbar {
  display: none;
} */

/* Hide scrollbar for IE, Edge and Firefox */
.tableData {
  -ms-overflow-style: none; /* IE and Edge */
  scrollbar-width: none; /* Firefox */
  /* height: 57vh;
    overflow: auto; */
}

.tableData::-webkit-scrollbar {
  width: 0.2rem;
  /* Adjust the width to make it as thin as you want */
}

.displayTable {
  overflow-x: scroll;
  overscroll-behavior-inline: contain;
  scrollbar-width: thin;
  scrollbar-color: #888 transparent;
}

.displayTable::-webkit-scrollbar {
  width: 8px !important;
  height: 8px;
}

.tableMain {
  border-collapse: collapse; /* Optional: Remove cell spacing */
  width: 100%;
  border-spacing: 13px !important;
  border-radius: 10px;
}

.tableMain th {
  /* Header styles go here */
}

.tableMain td.tableData {
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2); /* Add your desired shadow properties here */
  padding: 8px; /* Optional: Adjust padding to your preference */
}

.dataTable {
  width: 90%;
  margin: 0 auto;
  /* overflow: auto; */
}

.border_bottom {
  border-bottom: 2px solid red !important;
}
tr.border_bottom th {
  border: none;
  border-bottom: 1px solid white !important;
  padding: 10px;
  margin-bottom: 10px;
  text-transform: capitalize;
  color: white;
  font-size: 26px;
  font-weight: 500;
}

tr.bodyData td {
  border-bottom: 1px solid white;
  padding: 20px 0;
  text-align: center;
}

.inputBox {
  padding: 12px;
  background: rgba(217, 217, 217, 0.15);
  border: none;
  border-radius: 5px;
  color: white;
  margin: 0 6px;
  font-size: 20px;
  text-align: center;
  width: 300px;
}

.uploadBtn {
  font-size: 18px !important;
  width: 90px !important;
  background-color: #ED388F !important;
  color: white !important;
  margin-top: 20px !important;
  cursor: pointer;
  padding: 10px;
  border: none;
  border-radius: 10px;
}

.uploadBtn:hover {
  color: #1e1d1d !important;
}

.borderRedBottom {
  border-bottom: 2px solid white !important;
}

.containButton {
  padding: 0px 80px;
  margin-left: auto;
  display: flex;
  justify-content: end;
}

.buttons {
  border-radius: 12px;
  display: flex;
  height: 52px;
  padding: 12px 18px;
  justify-content: center;
  align-items: center;
  box-shadow: none;
  border: 0;
  font-size: 15px;
}

.SingleAddContainer {
  width: 80%;
  margin: 20px auto 18%;
  text-align: center;
}

.addRow {
  text-align: left;
  display: flex;
  align-items: center;
  margin: 20px 0;
  cursor: pointer;
  color: white;
  width: max-content;
}

.delete {
  padding: 13px;
  border-radius: 7px;
  border: none;
}