.profileHead{
    font-size: 1.5rem;
    font-weight: 600;
    line-height: 1.25;
    border-bottom: 0.5px solid #cdc8c8;
    padding: 5px;
    margin: 0 0 20px;
    color: #4c4848;
}



.displayEditfield{
    margin : 20px;
    width: 350px;
    margin:20px auto;
    border : 1px solid gray;
    border-radius: 10px;
  
  }


  .btnContainer{
    display: flex;
    justify-content: space-between;
    margin-bottom: 10px;
 }

.createBtn{
border : none;
color : white;
background-color: #ED388F;
padding: 10px 22px;
border-radius: 5px;
}