.mainBackground {
  height: 100vh;
  background: linear-gradient(112.75deg,
      rgba(157, 71, 169, 0.75) 0.42%,
      rgba(239, 64, 140, 0.75) 99.42%);
}

.heads{
  color: var(--color-main-white, #FFF);
  text-shadow: 0px 4px 10px rgba(0, 0, 0, 0.25);
  /* Font/Desktop/H3/Medium */
  font-family: 'Barlow-semibold', sans-serif;
  font-size: 32px;
  font-style: normal;
  font-weight: 500;
  line-height: 40px; /* 125% */
  letter-spacing: -0.64px; 
}

.subContainer {
  background-image: url("../../Assets/Onboarding/ed5newBg.png");
  background-size: 100% 100%;
  height: 100vh;
}

.heading {
  display: flex;
  align-items: center;
  max-width: fit-content;
  padding: 30px;
  color: white;
  font-size: 24px;
}

.centerContainer {
  max-width: 1400px;
  margin: 0 auto;
  text-align: center;
  margin-top: 8%;
}

.selectionHeading {
  /* color: white;
  margin-bottom: 60px;
  font-family: Barlow; */

  color: var(--color-main-white, #FFF);
  text-align: center;
  /* Font/Desktop/H1/Medium */
  font-family: 'Barlow-semibold';
  font-size: 40px;
  font-style: normal;
  font-weight: 400;
  line-height: 48px;
  /* 120% */
  letter-spacing: -0.8px;
}

.boards {
  display: flex;
  justify-content: center;
}

.containerBoard {
  width: 200px;
  border: 1px solid #8871d152;
  background-color: #ffffff3d;
  padding: 25px 20px;
  border-radius: 12px;
  font-size: 20px;
  color: white;
  font-family: 'Barlow-semibold';
  cursor: pointer;
  margin: 0 30px;
  border-radius: 10px;
  border: 1px solid var(--gradient-tabs-stroke, rgba(255, 255, 255, 0.50));
  background: var(--gradient-tabs-background, linear-gradient(238deg, rgba(255, 255, 255, 0.20) -2.49%, rgba(255, 255, 255, 0.04) 85.03%));
  box-shadow: 0px 4px 12px 0px rgba(0, 0, 0, 0.05);
  backdrop-filter: blur(35px);
}

.boardselect {
  background-color: white;
  color: rgb(0, 0, 0);
  /* font-family: Barlow; */
  font-size: 20px;
}

.btns {
  position: fixed;
  bottom: 41px;
  right: 75px;
  padding: 14px;
  width: 150px;
  border: none;
  border-radius: 12px;
  background-color: #fbd7e9;
  color: #f69bc7;
  font-size: 20px;
  font-weight: 600;
  cursor: pointer;
  font-family: "Barlow", sans-serif;
}