.mainBackground {
  height: 100vh;
  background: linear-gradient(
    112.75deg,
    rgba(157, 71, 169, 0.75) 0.42%,
    rgba(239, 64, 140, 0.75) 99.42%
  );
}

.subContainer {
  background-image: url("../../Assets/Onboarding/ed5newBg.png");
  background-size: 100% 100%;
  height: 100vh;
}

.heading {
  display: flex;
  align-items: center;
  max-width: fit-content;
  padding: 30px;
  color: white;
  font-size: 24px;
  color: var(--color-main-white, #fff);
  text-shadow: 0px 4px 10px rgba(0, 0, 0, 0.25);
  /* Font/Desktop/H3/Medium */
  font-family: "Barlow-semibold", sans-serif;
  font-size: 32px;
  font-style: normal;
  font-weight: 400;
  line-height: 40px; /* 125% */
  letter-spacing: -0.64px;
}

.centerContainer {
  max-width: 1400px;
  margin: 0 auto;
  text-align: center;
  margin-top: 8%;
}

.selectionHeading {
  color: white;
  margin-bottom: 60px;
}

.boards {
  display: flex;
  justify-content: space-around;
}

.containerBoard {
  width: 200px;
  border: 1px solid white;
  padding: 20px;
  border-radius: 12px;
  color: white;
  cursor: pointer;
}

.boardselect {
  background-color: white;
  color: pink;
}

.btns {
  position: fixed;
  bottom: 41px;
  right: 75px;
  padding: 14px;
  width: 150px;
  border: none;
  border-radius: 12px;
  background-color: #fbd7e9;
  color: #f69bc7;
  font-size: 20px;
  font-weight: 600;
  cursor: pointer;
  font-family: "Barlow", sans-serif;
}

.shifts {
  display: flex;
  width: max-content;
  margin: 0 auto;
  align-items: center;
}

.fontData {
  color: var(--color-main-white, #fff);
  /* Font/Desktop/H2/Medium */
  font-family: "Barlow-semibold", sans-serif;
  font-size: 30px;
  font-style: normal;
  font-weight: 400;
  line-height: 44px; /* 122.222% */
  letter-spacing: -0.72px;
}

.shiftSelection {
  border-radius: 6px;
  border: 1px solid var(--gradient-tabs-stroke, rgba(255, 255, 255, 0.5));
  background: var(
    --gradient-tabs-background,
    linear-gradient(
      238deg,
      rgba(255, 255, 255, 0.2) -2.49%,
      rgba(255, 255, 255, 0.04) 85.03%
    )
  );
  display: flex;
  margin: 0 10px;
  padding: 12px 32px;
  justify-content: center;
  align-items: center;
  gap: 10px;
  flex-shrink: 0;
  color: white;
}

.shiftSelected {
  background-color: white;
  color: #000000;
}

.shiftContainer {
  cursor: pointer;
}
