::-webkit-scrollbar {
  /* display: none; */
}

.Container{
  overflow: hidden;
}

.head{
  font-size: 25px;
}

.btns{
  margin-top: 20px !important;
}

.headerTitle {
  display: flex;
}

.title {

  font-weight: 500;
  font-size: 20px;
  color: #484848;
}

.videotext {
  color: #484848;
  font-weight: 500;
  font-size: 18px;
}


.buttonCon{
display: flex;
gap: 20px;


}

.uploadVideo{
padding: 18px 22px; 
border : none;
border-radius: 10px;
font-weight: 500;
color: #fff;

}


.bulkUpload{
background: #FFFFFF;
border: 1px solid #ED388F;
border-radius: 10px;
padding : 18px 22px;
cursor: pointer;
}



.videoTitle{
 color: #484848;
 font-size : 20px;
 margin-top:10px
}

.mainVideoContainer{
  display: flex;
}


.buttonCon{
    margin-bottom:20px;
}

.videoContainer{
    margin-top:40px
}

.cardMain{
  width: 100%;
  margin: 10px;
  position: relative;
  box-shadow: 2px 0px 4px 0px #bfb0b0;
  border-radius: 12px;
  cursor: pointer;
  overflow: hidden;
  background-repeat: no-repeat;
    background-size: 100% 100%;
    height: 273px;
}

.imgCard{
  width: 100%;
}

.customProgress{
  position: absolute;
  bottom: 0px;
  left: 0px;
  right: 0px;
}

.marginBottom{
  margin-bottom: 20px;
}

.imgLogo{
  position: absolute;
  right: 14px;
  top: 10px;
  width: 35px;
}

.circleGrey{
  background-color: #484848;
  text-align: center;
  padding: 5px;
  margin-bottom: 3px;
  border-radius: 10px;
  cursor: pointer;
}

.topics{
  font-size: 16px;
    /* margin-bottom: 10px; */
    font-weight: 700;
    width: 100%;
    color: white;
    padding: 47px 10px 32px;
    background-image: linear-gradient(#ffffff00, #000000e0);
}

.btns{
  font-size: 15px !important;
  width: 90px !important;
  background-color: #ED388F !important;
  color: white !important;
  margin-top: 20px !important;
}

.btns:hover{
  color: #1E1D1D !important;
}

.popUpCon {
  width: 50%;
  padding: 20px;
  border-radius: 20px;
}

.editHead {
  color: black;
  font-size: 22px;
  font-weight: 400;
}
.editPara {
  color: #ffffff;
  font-size: 22px;
  font-weight: 400;
}
.imagesCon {
  margin-bottom: 50px;
  margin-top: 50px;
}

.slideType {
  color: #ffffff;
  font-size: 20px;
  font-weight: 400;
}

.deletedEbookImg {
  width: 60%;
  border-radius: 20px;
  border: 2px solid #ed388f;
}

.btnsContainer {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  gap: 10%;
  margin: 20px 0px;
}

.deleteBtn {
  color: #ffffff;
  font-size: 22px;
  font-weight: 400;
  border: 1px solid #ffffff;
  border-radius: 6px;
  padding: 10px;
  cursor: pointer;
  background-color: #ed388f;
  width: 150px;
}
.cancelBtn {
  color: black;
  font-size: 22px;
  font-weight: 400;
  border: 1px solid #ED388F;
  width: 150px;
  border-radius: 6px;
  padding: 10px;
  background-color: transparent;
  cursor: pointer;
}