.mainBackground{
    height: 100vh;
    background: linear-gradient(112.75deg, rgba(157, 71, 169, 0.75) 0.42%, rgba(239, 64, 140, 0.75) 99.42%);
}

.subContainer{
    background-image: url('../../Assets/Onboarding/ed5newBg.png');
    background-size: 100% 100%;
    height: 100vh;
}

.mainSelection{
    display: flex;
    justify-content: flex-start;
    margin: 0 34px;
    border-bottom: 1px solid white;
    padding: 14px;
    color: white;
    font-family: 'Barlow';
    font-size: 21px;
}

.heading{
    display: flex;
    align-items: center;
    max-width: fit-content;
    padding: 30px;
    color: white;
    font-size: 24px;
}


.centerContainer{
    max-width: 1400px;
    margin: 0 auto;
    text-align: center;
    margin-top: 8%;
}

.selectionHeading{
    color: white;
    margin-bottom: 60px;
}

.boards{
    display: flex;
    justify-content: space-around;
}

.containerBoard{
    width: 200px;
    border: 1px solid white;
    padding: 20px;
    border-radius: 12px;
    color: white;
    cursor: pointer;
}

.boardselect{
    background-color: white;
    color: pink;
}

.btns {
    position: fixed;
    bottom: 41px;
    right: 75px;
    padding: 14px;
    width: 150px;
    border: none;
    border-radius: 12px;
    background-color: #fbd7e9;
    color: #f69bc7;
    font-size: 20px;
    font-weight: 600;
    cursor : pointer;
  }
  

.headerContainer{
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 0 30px;
}

.dragDrop{
    border: 1px solid white;
    width: 636px;
    margin: 59px auto;
    text-align: center;
    padding: 79px;
    box-sizing: border-box;
}

.sampleDownload{
    padding: 11px 35px;
    background: white;
    border-radius: 12px;
    color: #a57981;
    text-decoration: none;
}

.mainBackground {
    height: 100%;
    background: linear-gradient(112.75deg, rgba(157, 71, 169, 0.75) 0.42%, rgba(239, 64, 140, 0.75) 99.42%);
}

.heads{
    color: var(--color-main-white, #FFF);
    text-shadow: 0px 4px 10px rgba(0, 0, 0, 0.25);
    /* Font/Desktop/H3/Medium */
    font-family: 'Barlow-semibold', sans-serif;
    font-size: 32px;
    font-style: normal;
    font-weight: 500;
    line-height: 40px; /* 125% */
    letter-spacing: -0.64px; 
}

.subContainer {
    background-image: url('../../Assets/Onboarding/ed5newBg.png');
    background-size: 100% 100%;
    height: 100%;
    padding: 0 0 22vh;
}

.heading {
    display: flex;
    align-items: center;
    max-width: fit-content;
    padding: 30px;
    color: white;
}

.subjectData{
    display: flex;
    justify-content: space-around;
    margin: 20px;
}

.number{
    padding: 10px 38px;
    margin: 0 10px;
    border-radius: 5px;
    box-sizing: border-box;
    /* box-shadow: 1px 2px 2px grey; */
    background-color: #636db58f;
    background-image: linear-gradient(to right, rgba(255,0,0,0), rgb(112 105 159));
    display: flex;
    align-items: center;
    color: white;
    justify-content: center;
}

.subjectMain{
    margin-right: 10px;
    border-radius: 7px;
    color: white !important;
    background-color: #ffffff26;
}
.centerContainer {
    max-width: 1400px;
    margin: 0 auto 17%;
    text-align: center;
    padding: 10px 85px;
}

.select{
    margin-right: 10px;
}
.selectionHeading {
    color: var(--color-main-white, #FFF);
    text-align: center;
    /* Font/Desktop/H1/Medium */
    font-family: 'Barlow-semibold', sans-serif;
    font-size: 40px;
    font-style: normal;
    font-weight: 500;
    line-height: 48px;
    /* 120% */
    letter-spacing: -0.8px;
}

.titles{
    color: var(--color-main-white, #FFF);
    text-align: center;
    /* Font/Desktop/H5/Medium */
    font-family: 'Barlow-semibold', sans-serif;
    font-size: 24px;
    font-style: normal;
    font-weight: 500;
    line-height: 32px; /* 133.333% */
    letter-spacing: -0.48px;
}

.boards {
    display: flex;
    justify-content: space-around;
}

.containerBoard {
    width: 200px;
    border: 1px solid white;
    padding: 20px;
    border-radius: 12px;
    color: white;
    cursor: pointer;
}

.boardselect {
    background-color: white;
    color: pink;
}

.btns {
    position: fixed;
    bottom: 5%;
    right: 3%;
    padding: 14px;
    width: 150px;
    border: none;
    border-radius: 12px;
    background-color: #fbd7e9;
    color: #f69bc7;
    font-size: 20px;
    font-weight: 600;
    cursor: pointer;
}


.boxData {
    color: white;
    width: -moz-fit-content;
    width: 200px;
    font-size: 22px;
    font-weight: bold;
    /* flex: 1 1; */
    margin-right: 10px;
    margin-bottom: 30px;
}