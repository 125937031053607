.carouselContainer {
    position: relative;
    width: 100%;
    /* max-width: 800px;  */
    margin: 0 auto;
  }
   
  .mySwiper {
    width: 100%;
    
  }
   
  .slideGroup {
    display: flex;
    justify-content: space-between;
  }
  
  .arrows{
    color: #ED388F;
    cursor: pointer;
    margin-right: 10px;
  }
  