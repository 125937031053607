.mainBackground {
  min-height: 100vh;
  background: linear-gradient(
    112.75deg,
    rgba(157, 71, 169, 0.75) 0.42%,
    rgba(239, 64, 140, 0.75) 99.42%
  );
}

.heads {
  color: var(--color-main-white, #fff);
  text-shadow: 0px 4px 10px rgba(0, 0, 0, 0.25);
  /* Font/Desktop/H3/Medium */
  font-family: "Barlow-semibold", sans-serif;
  font-size: 32px;
  font-style: normal;
  font-weight: 500;
  line-height: 40px; /* 125% */
  letter-spacing: -0.64px;
}

.subContainer {
  background-image: url("../../Assets/Onboarding/ed5newBg.png");
  background-size: 100% 100%;
  min-height: 100vh;
}

.timetableMain{
  padding: 20px;
  max-width: 1500px;
  margin: 0 auto;
}

.heading {
  display: flex;
  align-items: center;
  max-width: fit-content;
  padding: 10px 30px;
  color: white;
  font-size: 24px;
}

.centerContainer {
  max-width: 1400px;
  margin: 0 auto;
  text-align: center;
  margin-top: 8%;
}

.selectionHeading {
  /* color: white;
    margin-bottom: 60px;
    font-family: Barlow; */

  color: var(--color-main-white, #fff);
  text-align: center;
  /* Font/Desktop/H1/Medium */
  font-family: "Barlow-semibold";
  font-size: 40px;
  font-style: normal;
  font-weight: 400;
  line-height: 48px;
  /* 120% */
  letter-spacing: -0.8px;
}

.boards {
  display: flex;
  justify-content: center;
}

.containerBoard {
  width: 200px;
  border: 1px solid #8871d152;
  background-color: #ffffff3d;
  padding: 25px 20px;
  border-radius: 12px;
  font-size: 20px;
  color: white;
  font-family: "Barlow-semibold";
  cursor: pointer;
  margin: 0 30px;
  border-radius: 10px;
  border: 1px solid var(--gradient-tabs-stroke, rgba(255, 255, 255, 0.5));
  background: var(
    --gradient-tabs-background,
    linear-gradient(
      238deg,
      rgba(255, 255, 255, 0.2) -2.49%,
      rgba(255, 255, 255, 0.04) 85.03%
    )
  );
  box-shadow: 0px 4px 12px 0px rgba(0, 0, 0, 0.05);
  backdrop-filter: blur(35px);
}

.boardselect {
  background-color: white;
  color: rgb(0, 0, 0);
  /* font-family: Barlow; */
  font-size: 20px;
}

.btns {
  position: fixed;
  bottom: 41px;
  right: 75px;
  padding: 14px;
  width: 150px;
  border: none;
  border-radius: 12px;
  background-color: #fbd7e9;
  color: #f69bc7;
  font-size: 20px;
  font-weight: 600;
  cursor: pointer;
  font-family: "Barlow", sans-serif;
}

.containerBoard {
  width: 200px;
  border: 1px solid white;
  padding: 20px;
  border-radius: 12px;
  color: white;
  cursor: pointer;
}

.boardselect {
  background-color: white;
  color: pink;
}

.tableContainer{
  background: #46317152 !important;  
}

.btns {
  position: fixed;
  bottom: 41px;
  right: 75px;
  padding: 14px;
  width: 150px;
  border: none;
  border-radius: 12px;
  background-color: #fbd7e9;
  color: #f69bc7;
  font-size: 20px;
  font-weight: 600;
  cursor: pointer;
  font-family: "Barlow", sans-serif;
}

.mainTable {
  display: flex;
  /* justify-content: space-around; */
}

.tableContainersMain {
  margin-top: 15px;
  width: 65%;
  /* height: 70vh; */
  /* overflow-y: auto; */
  padding: 10px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
}

.tableContainersMain::-webkit-scrollbar {
  width: 0.2rem;
  /* Adjust the width to make it as thin as you want */
}

.tableData {
  border: 1px solid rgb(255, 245, 245);
  width: 100%;
  padding: 10px;
}

.tableData,
th,
td {
  border: 1px solid rgb(255, 245, 245);
  border-collapse: collapse !important;
  margin: 5px;
  padding: 17px;
  font-family: "Barlow-semibold", sans-serif;
  font-size: 19px;
}

.selected {
  border: 1px solid #ffffff;
  border-radius: 5px;
  margin-bottom: 8px;
  padding: 10px;
  background-color: white;
  border: 1px solid #ffffff;
  border-radius: 5px;
  margin-bottom: 8px;
  padding: 10px;
  color: rgb(0, 0, 0);
  text-align: center;
  font-size: 20px;
  /* font-family: Barlow; */
}

.normalClass {
  border: 1px solid #ffffff;
  border-radius: 5px;
  margin-bottom: 8px;
  padding: 10px;
  color: white;
  text-align: center;
  font-size: 20px;
  /* font-family: Barlow; */
}

.classListMain {
  height: 70vh;
  overflow: auto;

  width: 240px;
  padding: 10px;
}

.classListMain::-webkit-scrollbar {
  width: 0.2rem;
  /* Adjust the width to make it as thin as you want */
}

/* Style the scrollbar thumb as a thin line */
::-webkit-scrollbar-thumb {
  background: #e6e4e4;
  /* Set the color of the scrollbar thumb */
}

.shiftsTypes {
  display: flex;
  justify-content: space-around;
}

.singleShift {
  display: flex;
  padding: 8px 53px;
  justify-content: center;
  align-items: center;
  gap: 10px;
  background-color: #ffffff66;
  flex: 1 0;
  margin: 10px;
  color: white;
  border-radius: 8px;
  font-size: 20px;
  /* font-family: Barlow; */
  cursor: pointer;
}

/* Style the first child without a left margin */
.singleShift:first-child {
  margin-left: 0;
}

/* Style the last child without a right margin */
.singleShift:last-child {
  margin-right: 0;
}

.selectedSingleShift {
  background-color: white;
  color: black;
}

.cls {
  height: 58vh;
  overflow: auto;
}

.cls::-webkit-scrollbar {
  width: 0.2rem;
  /* Adjust the width to make it as thin as you want */
}

.curriculumText {
  font-size: 22px;
  font-weight: 500;
  color: white;
  padding: 10px;
  margin: 0;
}
.tableHeadRow {
  /* border-bottom: 2px soild white !important; */
  background: rgba(40, 40, 40, 0.02);
  /* border: none !important; */
  border-bottom: 1px solid white !important;
}
.tableDataRow {
  background: rgba(196, 196, 196, 0.032);;

  border: none !important;
}
.tableHeadItem {
  color: white !important;
  font-size: 24px !important;
  font-weight: 500 !important;
  text-align: center !important;
  border: none !important;

  border-bottom: 2px soild white !important;
}
.tableRowItem {
  color: white !important;
  font-size: 18px !important;
  font-weight: 500 !important;
  text-align: center !important;
  border: none !important;
  font-family: "Barlow-semibold", sans-serif;
}
