.editCon {
  display: flex;
  justify-content: end;
}

.editItem {
  text-align: center;
  width: 120px;
  padding: 10px;
  border-radius: 5px;
  box-shadow: 0px 0px 4px rgba(0, 0, 0, 0.3);
  cursor: pointer;
}

.mainContainer {
  display: flex;
  flex-wrap: wrap;
  margin-left: 10px;
}

.rightInfocon {
  display: flex;
  align-items: center;
  /* width: 23%; */
  margin: 12px;
  padding: 40px 21px;
  background: #fffefe;
  /* border: 1px solid rgba(228, 226, 226, 0.28); */
  /* box-shadow: 0 0px 4px rgba(0, 0, 0, 0.1); */ /*removed border by hari */
  box-shadow: 2px 2px 6px #e7e7e7;
  border: none;
  border-radius: 5px;
  box-sizing: border-box;
}

.textField1,
.textField2,
.textField3 {
  display: flex;
  width: 420px;
  margin: 10px;
  justify-content: space-between;
}

.textField {
  margin: 12px;
  width: 200px;
}

.boxAddressCon {
  display: flex;
  flex-wrap: wrap;
}

.displayEditfield {
  width: 43%;
  margin: 20px;
}

.AddandeditCon {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.displayEditfield {
  /* box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px; */
  border: 1px solid gray;
  border-radius: 10px;
  margin-top: 10px;
}

.addressContainer {
  padding-left: 10px;
}

.drawerAddressDetails {
  color: gray;
  margin: 0px;
}

.editIconContainer {
  display: flex;
  justify-content: end;
  margin: 5px 5px 0px 5px;
}

.addressInfoCon {
  display: flex;
  align-items: center;
  width: 60%;
  border: 1px solid red;
}

.imageCon {
  /* width : 20% */
}

.InfoCon {
  width: 70%;
  margin-left: 5px;
}

.leftCon {
  width: 30%;
}

.mainImage {
  width: 60px;
  height: 60px;
  margin: 0 20px 0 0;
}
/* .InfoCon{
      margin:0px;
      margin-left: 4px;
  } */

.title {
  margin: 0px;
  /* white-space: nowrap; */
  color: #ED388F;
  font-weight: bold;
  font-size: 16px;
}

.desc {
  color: #5a5a5a;
  margin: 0px;
  word-break: break-all;
}
