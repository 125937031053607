.mainBackground {
    height: 100vh;
    background: linear-gradient(112.75deg, rgba(157, 71, 169, 0.75) 0.42%, rgba(239, 64, 140, 0.75) 99.42%);
}

.heads{
    color: var(--color-main-white, #FFF);
    text-shadow: 0px 4px 10px rgba(0, 0, 0, 0.25);
    /* Font/Desktop/H3/Medium */
    font-family: 'Barlow-semibold', sans-serif;
    font-size: 32px;
    font-style: normal;
    font-weight: 500;
    line-height: 40px; /* 125% */
    letter-spacing: -0.64px; 
}

.subContainer {
    background-image: url('../../Assets/Onboarding/ed5newBg.png');
    background-size: 100% 100%;
    height: 100%;
    padding: 0 0 6%;
}

.heading {
    display: flex;
    align-items: center;
    max-width: fit-content;
    padding: 30px;
    color: white;
}


.centerContainer {
    max-width: 1400px;
    margin: 0 auto;
    text-align: center;
    margin-top: 5%;
    padding: 10px 85px;
}

.selectionHeading {
    color: var(--color-main-white, #FFF);
    text-align: center;
    /* Font/Desktop/H1/Medium */
    font-family: 'Barlow-semibold', sans-serif;
    font-size: 40px;
    font-style: normal;
    font-weight: 500;
    line-height: 48px;
    /* 120% */
    letter-spacing: -0.8px;
}

.titles{
    color: var(--color-main-white, #FFF);
    text-align: center;
    /* Font/Desktop/H5/Medium */
    font-family: 'Barlow-semibold', sans-serif;
    font-size: 24px;
    font-style: normal;
    font-weight: 500;
    line-height: 32px; /* 133.333% */
    letter-spacing: -0.48px;
}

.boards {
    display: flex;
    justify-content: space-around;
}

.containerBoard {
    width: 200px;
    border: 1px solid white;
    padding: 20px;
    border-radius: 12px;
    color: white;
    cursor: pointer;
}

.boardselect {
    background-color: white;
    color: pink;
}

.btns {
    position: fixed;
    bottom: 41px;
    right: 75px;
    padding: 14px;
    width: 150px;
    border: none;
    border-radius: 12px;
    background-color: #fbd7e9;
    color: #f69bc7;
    font-size: 20px;
    font-weight: 600;
    cursor: pointer;
    font-family: "Barlow", sans-serif;
}


.boxData {
    color: white;
    width: fit-content;
    font-size: 22px;
    font-weight: bold;
    flex: 1;
    margin-right: 10px;
}