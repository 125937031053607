.popUpCon{
    width : 40%;
    padding : 20px;
    border-radius : 20px;
 
 }
 .heading {
    color : #808080;
    font-size : 30px;
    font-weight : 600;
 }