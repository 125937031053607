.container {
    box-shadow: 0px 0px 7px rgb(0 0 0 / 29%);
    border-radius: 5px;
    padding: 15px;
    margin-bottom:15px;
  }
  .Ebooks_main{
    display: flex;
    justify-content: space-between;
  }

  .head{
    font-size: 25px;
  }

  .subHead{
    font-size: 19px;
    font-weight: bold;
  }

  .imgLogo{
    position: absolute;
    right: 14px;
    top: 10px;
    width: 30px;
  }
  
  .imageContainer {
    width: 8%;
  }
  
  .mainImage {
    width: 100%;
  }

  .cardMind{
    width: 95%;
    /* width: 100%; */
    position: relative;
    box-shadow: rgb(191, 176, 176) 2px 0px 4px 0px;
    cursor: pointer;
    /* background-size: 100% 100%; */
    height: 250px;
    margin: 10px;
    border-radius: 12px;
    overflow: hidden;
  }
  
  .cardContainer {
    display: flex;
    align-items: center;
  }
  
  .dateContainer {
    margin-top: 10px;
  }
  
  .studentsAttempted {
    background: #69d96d;
    border: 1px solid rgba(105, 217, 109, 0.55);
    border-radius: 10px;
    padding: 8px;
    margin: 0px;
    margin-top:10px;
    color:white;
  }
  
  .infoflexCon {
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-left: 20px;
    padding: 0px 10px;
  }
  
  .infoHeader {
    font-weight: 500;
    font-size: 18px;
  }
  
  .textColor {
    color: #1E1D1D;
  }

  .imgCard{
    width: 100%;
    height: 100%;
    object-fit: cover;
  }
  
  .BooksContainer{
    padding: 10px 35px;
  }

  .btns{
    font-size: 15px !important;
    width: 90px !important;
    background-color: #ED388F !important;
    color: white !important;
    margin-top: 20px !important;
  }

  .btns:hover{
    color: #1E1D1D !important;
  }




  .popUpCon {
    width: 50%;
    padding: 20px;
    border-radius: 20px;
  }
  
  .editHead {
    color: black;
    font-size: 22px;
    font-weight: 400;
  }
  .editPara {
    color: #ffffff;
    font-size: 22px;
    font-weight: 400;
  }
  .imagesCon {
    margin-bottom: 50px;
    margin-top: 50px;
  }
  
  .slideType {
    color: #ffffff;
    font-size: 20px;
    font-weight: 400;
  }
  
  .deletedEbookImg {
    width: 60%;
    border-radius: 20px;
    border: 2px solid #ed388f;
  }
  
  .btnsContainer {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    gap: 10%;
    margin: 20px 0px;
  }
  
  .deleteBtn {
    color: #ffffff;
    font-size: 22px;
    font-weight: 400;
    border: 1px solid #ffffff;
    border-radius: 6px;
    padding: 10px;
    cursor: pointer;
    background-color: #ed388f;
    width: 150px;
  }
  .cancelBtn {
    color: black;
    font-size: 22px;
    font-weight: 400;
    border: 1px solid #ED388F;
    width: 150px;
    border-radius: 6px;
    padding: 10px;
    background-color: transparent;
    cursor: pointer;
  }
  .topicData{
    display: flex;
    font-size: 24px;
    align-items: center;
    margin-bottom: 20px;
  }
  .arrows{
    color: #ED388F;
    cursor: pointer;
    margin-right: 10px;
  }