.mainBackground {
    /* height: 120vh; */
    height: 100%;
    background: linear-gradient(112.75deg, rgba(157, 71, 169, 0.75) 0.42%, rgba(239, 64, 140, 0.75) 99.42%);
}

.subContainer {
    background-image: url('../../Assets/Onboarding/ed5newBg.png');
    background-size: 100% 100%;
    /* height: 120vh; */
    height : 100%;
    padding: 0 0 40%;
}

.heading {
    display: flex;
    align-items: center;
    max-width: fit-content;
    padding: 30px;
    color: white;
    font-size: 24px;
    font-family: "Barlow-semibold", sans-serif;
}


.centerContainer {
    max-width: 1400px;
    margin: 0 auto;
    text-align: center;
    margin-top: 8%;
}

.selectionHeading {
    color: white;
    margin-bottom: 60px;
}

.boards {
    display: flex;
    justify-content: space-around;
}

.containerBoard {
    width: 200px;
    border: 1px solid white;
    padding: 20px;
    border-radius: 12px;
    color: white;
    cursor: pointer;
}

.boardselect {
    background-color: white;
    color: pink;
}

.btns {
    position: fixed;
    bottom: 41px;
    right: 75px;
    padding: 14px;
    width: 150px;
    border: none;
    border-radius: 12px;
    background-color: #fbd7e9;
    color: #f69bc7;
    font-size: 20px;
    font-weight: 600;
    cursor: pointer;
}

.teacherContainer {
    display: flex;
    max-width: 1400px;
    margin: 0 auto 24px;
}

.teacherBox {
    width: 50%;
    height: 82vh;
    overflow: auto;
}

.teacherBox::-webkit-scrollbar {
    width: 0.2rem;
    /* Adjust the width to make it as thin as you want */
}

.subjectMain {
    display: flex;
    flex-wrap: wrap;
}

.subjectBox {
    margin: 10px;
    border-radius: 7px;
    border-radius: 6px;
    border: 1px solid var(--gradient-tabs-stroke, rgba(255, 255, 255, 0.50));
    background: var(--gradient-tabs-background-active, linear-gradient(rgba(255, 255, 255, 0.6) 2.05%, rgb(236 236 220 / 0%) 90.08%));
    box-shadow: 0px 4px 17px 0px rgba(255, 255, 255, 0.20);
    backdrop-filter: blur(35px);
    display: flex;
    padding: 6px 21px;
    justify-content: center;
    align-items: center;
    color: white;
    text-align: center;
    /* font-family: Barlow; */
    font-size: 19px;
    font-style: normal;
    font-weight: 400;
    line-height: 28px;
}

.selected{
    background-color: white;
    color: black;
}

.fontData {
    font-size: 36px;
    color: white;
    font-weight: bold;
    margin-bottom: 20px;
    font-family: "Barlow-semibold", sans-serif;
}

.groupingBox {
    display: flex;
    border: 2px solid #efdede;
    background-color: #cdcdcd85;
    min-height: 200px;
    margin: 10px;
    padding: 10px;
    border-radius: 10px;
    background: linear-gradient(to top right, transparent, #ffffff78);
}

.selectedTeacher {
    background-color: #f3c7c752;
    padding: 10px;
}

.subjectContainer {
    border: 2px solid white;
    max-width: inherit;
    margin: 10px;
    position: relative;
    border-radius: 10px;
}

.selectSubjectContainer {
    border: 2px solid #6f65a5ad;
    max-width: inherit;
    margin: 10px;
    position: relative;
    border-radius: 10px;
}

.selectedgroupTitle {
    padding: 7px 25px;
    background: #6f65a5ad;
    cursor: pointer;
    border-top-left-radius: 6px;
    border-top-right-radius: 6px;
    font-size: 20px;
    /* font-family: Barlow; */
}

.groupTitle {
    padding: 7px 25px;
    background: white;
    cursor: pointer;
    border-top-left-radius: 6px;
    border-top-right-radius: 6px;
    font-size: 20px;
    /* font-family: Barlow; */
}

.subGrouping {
    margin: 10px;
    background-color: white;
    padding: 10px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    border-radius: 4px;
    font-size: 20px;
    /* font-family: Barlow; */
}

.nextButton {
    padding: 11px 60px;
    border: none;
    border-radius: 12px;
    background-color: white;
}

.overview {
    font-size: 19px;
    font-weight: 500;
}

.contentView {
    padding: 10px 30px;
}

.btnColor {
    padding: 10px 20px;
    margin: 10px;
    border: 1px solid white;
    border-radius: 12px;
    font-size: 20px;
    color: white;
    font-family: "Barlow-semibold", sans-serif;
}

.btnSelected {
    background-color: white;
    color: black;
}

.listing {
    display: flex;
    justify-content: space-around;
    align-items: center;
}