.mainSpecial{
    display: flex;
    flex-wrap: wrap;
    margin-top: 60px;
}

.mainBox{
    margin: 10px;
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 10px;
    background: #FFFFFF;
    /* box-shadow: 4px -4px 4px rgb(0 0 0 / 10%), -4px 4px 4px rgb(0 0 0 / 10%); */
    /* box-shadow: 0 0 10px rgba(0, 0, 0, 0.2);  */
    box-shadow: 2px 2px 6px #e7e7e7;

    border-radius: 5px;
    /* width: 30%; */
}


.image{
    width: 50px;
}

.text{
    color: #717070;
    font-size: 18px;
    color: #ED388F;
    white-space: nowrap;
}