.mainData{
    margin: 20px 0;
}

.drawerContain{
    padding: 20px;
}

.textData{
    margin: 10px;
}

.shiftsTypes {
    display: flex;
    justify-content: space-around;
  }
  
  .singleShift {
    display: flex;
    padding: 8px 53px;
    justify-content: center;
    align-items: center;
    gap: 10px;
    background-color: #ED388F;
    flex: 1 0;
    margin: 10px;
    color: white;
    border-radius: 8px;
    font-size: 20px;
    /* font-family: Barlow; */
    cursor: pointer;
    white-space: nowrap;
  }
  
  /* Style the first child without a left margin */
  .singleShift:first-child {
    margin-left: 0;
  }
  
  /* Style the last child without a right margin */
  .singleShift:last-child {
    margin-right: 0;
  }

  .selected {
    border-radius: 5px;
    margin-bottom: 8px;
    padding: 10px;
    background-color: #ED388F;
    border-radius: 5px;
    margin-bottom: 8px;
    padding: 10px;
    color: rgb(0, 0, 0);
    text-align: center;
    font-size: 20px;
    color: white;
    border: 1px solid #ED388F;
    /* font-family: Barlow; */
  }
  
  .normalClass {
    border: 1px solid #ED388F;
    border-radius: 5px;
    margin-bottom: 8px;
    padding: 10px;
    color: #ED388F;
    text-align: center;
    font-size: 20px;
    /* font-family: Barlow; */
  }