.mainBackground {
  height: 100%;
  background: linear-gradient(
    112.75deg,
    rgba(157, 71, 169, 0.75) 0.42%,
    rgba(239, 64, 140, 0.75) 99.42%
  );
}

.whiteColor {
  color: white;
  font-family: "Barlow", sans-serif;
}

.headingText {
  font-size: 22px;
  font-weight: 400;
  color: var(--color-main-white, #fff);
  font-family: "Barlow-semibold", sans-serif;
  font-size: 24px;
  font-style: normal;
}

.subContainer {
  background-image: url("../../Assets/Onboarding/ed5newBg.png");
  background-size: 100% 100%;
  height: 100%;
  padding: 0 0 20%;
}

.heading {
  display: flex;
  align-items: center;
  max-width: fit-content;
  padding: 10px 30px;
  color: white;
  font-size: 24px;
  color: var(--color-main-white, #fff);
  text-shadow: 0px 4px 10px rgba(0, 0, 0, 0.25);
  /* Font/Desktop/H3/Medium */
  font-family: "Barlow-semibold", sans-serif;
  font-size: 32px;
  font-style: normal;
  font-weight: 500;
  line-height: 40px; /* 125% */
  letter-spacing: -0.64px;
}

.centerContainer {
  /* max-width: 1400px; */
  margin: 7px 70px 0;
  text-align: center;
  /*margin-top: 8%; */
}

.selectionHeading {
  color: white;
  margin-bottom: 55px;
  color: var(--color-main-white, #fff);
  text-align: center;
  font-family: 'Barlow-semibold', sans-serif;
  font-size: 36px;
  font-style: normal;
  font-weight: 400;
  /* line-height: 48px; */
  letter-spacing: -0.8px;
}

.boards {
  display: flex;
  justify-content: space-between;
}

.gradeClass {
  width: 300px;
  /* height: 70vh;
  overflow: auto; */
}

.gradeClass::-webkit-scrollbar {
  width: 0.2rem;
  /* Adjust the width to make it as thin as you want */
}

.classes {
  width: 60%;
  text-align: start;
  border-radius: 10px;
  padding: 10px 20px;
  background: #ffffff42;
  min-height: 35vh;
}

.containerBoard {
  width: 200px;
  border: 1px solid white;
  padding: 20px;
  border-radius: 12px;
  color: white;
  cursor: pointer;
}

.boardselect {
  background-color: white;
  color: pink;
}

.btns {
  position: fixed;
  bottom: 41px;
  right: 35px;
  padding: 14px;
  width: 150px;
  border: none;
  border-radius: 12px;
  background-color: #fbd7e9;
  color: #f69bc7;
  font-size: 20px;
  font-weight: 600;
  cursor: pointer;
  font-family: "Barlow", sans-serif;
}

.textBox {
  margin: 10px;
  width: 100px;
  margin-right: 20%;
}

.classContainer {
  display: flex;
  margin-bottom: 20px;
  flex-wrap: wrap;
}

.classData {
  border: 1px solid white;
  margin: 0 10px 10px;
  padding: 5px 20px;
  font-size: 20px;
  border-radius: 3px;
  background: white;
  color: #cf9ca5;
  border-radius: 6px;
  border: 1px solid var(--gradient-tabs-stroke, rgba(255, 255, 255, 0.5));

  background: var(
    --gradient-tabs-background-active,
    linear-gradient(
      151deg,
      rgba(255, 255, 255, 0.9) 7.05%,
      rgba(255, 255, 255, 0.72) 90.08%
    )
  );
  box-shadow: 0px 4px 17px 0px rgba(255, 255, 255, 0.2);
  backdrop-filter: blur(35px);
  color: var(--color-neutral-700, #404040);
  text-align: center;
  text-shadow: 0px 10px 20px rgba(0, 0, 0, 0.25);
  /* Font/Desktop/H6/Medium */
    font-family: 'Barlow-semibold', sans-serif;
  font-size: 20px;
  font-style: normal;
  font-weight: 400;
  line-height: 28px; /* 140% */
  letter-spacing: -0.4px;
}

.classData:first-child {
  margin-left: 0px;
}

.textfields {
  color: white;
}

.textfields:hover {
  border: none;
}
